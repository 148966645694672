import { Form, Formik, Field as F } from 'formik';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getParentIngredients, updateIngredient } from '../actions';
import { Ingredient, ReduxAction, ParentIngredientList, ConstraintList, UpdateIngredientInitialValues } from '../types';
import { Button } from './button';
import Field from './field';

interface IngredientModalValues {
  title: string;
  ingredient_constraints: Array<{
    id: string;
    scale?: number;
  }>;
  parentId?: string;
}
const IngredientChangeParentButton = styled.button`
  border-radius: 15px;
  background-color: whitesmoke;
  width: 150px;
  margin-left: 2rem;
`;

const Modal = styled.div`
  background: white;
  border: 2px black solid;
  padding: 5%;

  position: fixed;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -25%;
  z-index: 2;

  overflow: scroll;
`;

const ConstraintListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1%;
`;

interface Props {
  getParentIngredientsAction: (search?: string) => ReduxAction<ParentIngredientList>;
  updateIngredientAction: (ingredient?: Ingredient) => ReduxAction<Ingredient>;
  closeUpdateIngredientModal: () => void;
  parentIngredients: ParentIngredientList;
  constraints: ConstraintList;
  initialValues: UpdateIngredientInitialValues;
}

const mapStateToProps = ({ ingredient, parentIngredients, constraints }) => ({
  ingredient,
  parentIngredients,
  constraints,
});

const mapDispatchToProps = (dispatch) => ({
  updateIngredientAction: (ingredient?: Ingredient) => dispatch(updateIngredient(ingredient)),
  getParentIngredientsAction: (search?: string) => dispatch(getParentIngredients(search)),
});

const IngredientModal: React.FC<Props> = ({
  updateIngredientAction,
  closeUpdateIngredientModal,
  getParentIngredientsAction,
  parentIngredients,
  constraints,
  initialValues,
}) => {
  const [showParentIngredientList, setShowParentIngredientList] = useState<boolean>(false);
  const [parentIngredientTitle, setParentIngredientTitle] = useState<string>('');

  const onSubmit = (body) => {
    const constraints = body.ingredient_constraints?.map((constraint) => {
      constraint.scale = Number(constraint.scale);
      const formattedConstraint = { ...constraint, constraint_id: constraint.id };
      return formattedConstraint;
    });
    const formattedBody = { ...body, ingredient_constraints: constraints?.filter((i) => i) };

    updateIngredientAction(formattedBody);
    closeUpdateIngredientModal();
  };

  const onParentIngredientFocus = () => {
    setShowParentIngredientList(true);
  };

  const SCALE = [
    { label: '1 - Bad', value: 1 },
    { label: '2 - Neutral', value: 2 },
    { label: '3 - Good', value: 3 },
  ];

  return (
    <Modal>
      <CloseButton onClick={closeUpdateIngredientModal} buttonStyle="primary">
        X
      </CloseButton>
      <h1>Update Ingredient</h1>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key in keyof IngredientModalValues]?: any } = {};
          if (!values.title) {
            errors.title = 'Required';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <Form>
            {values.parentId ? (
              <div>
                <span>
                  Parent Ingredient ID: <b>{values.parentTitle}</b>
                </span>
                <IngredientChangeParentButton
                  onClick={() => {
                    setFieldValue('parentId', '');
                  }}
                >
                  change parent
                </IngredientChangeParentButton>
              </div>
            ) : (
              <Field
                title="Parent Ingredient"
                name="parentIngredientTitle"
                onChange={(e) => {
                  e.preventDefault();
                  const { parent_ingredient_search } = e.target;
                  getParentIngredientsAction(parent_ingredient_search);
                  setParentIngredientTitle(e.target.value);
                }}
                onBlur={handleBlur}
                onFocus={onParentIngredientFocus}
                value={values.parentId}
              />
            )}

            {showParentIngredientList &&
              parentIngredients?.map((parentIngredient) => {
                if (parentIngredient.title.includes(parentIngredientTitle)) {
                  return (
                    <ListItem
                      onClick={() => {
                        setFieldValue('parentId', parentIngredient.id);
                        setFieldValue('parentTitle', parentIngredient.title);
                        setShowParentIngredientList(false);
                      }}
                    >
                      {parentIngredient.title}
                    </ListItem>
                  );
                }
              })}

            <Field
              title="Title"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              // the browser gives me an error about this value but if I don't include it then TS gives me a props error
              value={values.title}
            />

            {/* List all possible constraints */}
            <ConstraintListDiv role="group" aria-labelledby="checkbox-group">
              {constraints.map(({ id, title, constraint_id }, i) => (
                <ListItem key={id}>
                  <label>
                    <input
                      type="checkbox"
                      value={constraint_id}
                      onChange={() => {
                        const key = `ingredient_constraints[${i}].id`;
                        const keyStatus = `ingredient_constraints[${i}].status`;
                        getParentIngredientsAction;
                        if (!values.ingredient_constraints[i]?.constraint_id) {
                          setFieldValue(key, id);
                        } else {
                          setFieldValue(key, undefined);
                        }
                      }}
                    />
                    {title}
                  </label>

                  {/* If constraint selected, show dropdown for scale */}
                  {!!values.ingredient_constraints[i]?.id && (
                    <>
                      <select
                        name={`ingredient_constraints[${i}].scale`}
                        value={
                          values.ingredient_constraints[i].scale ? values.ingredient_constraints[i].scale : 'DEFAULT'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: 'block' }}
                      >
                        <option value={'DEFAULT'} label="Select scale (1-3)" disabled />
                        {SCALE.map(({ label, value }) => (
                          <option key={value} value={value} label={label} />
                        ))}
                      </select>
                    </>
                  )}
                </ListItem>
              ))}
            </ConstraintListDiv>

            <Button type="submit" disabled={isSubmitting} buttonStyle="primary">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModal);
