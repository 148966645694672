import React, { useState } from 'react';
import styled from 'styled-components';
import { Ingredient } from '../types';
import UpdateIngredientModal from './UpdateIngredientModal';

const IngredientListButton = styled.button`
  border-radius: 15px;
  background-color: whitesmoke;
  width: 50%;
`;
const IngredientListItemWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: whitesmoke;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0;
`;
const IngredientListItemColumn = styled.div`
  padding: 1%;
  width: 100%;
  p {
    margin: auto;
  }
`;
interface Props {
  ingredient: Ingredient;
  parentId: number;
}

const IngredientListItem: React.FC<Props> = ({ ingredient, parentId }) => {
  const [showModal, setShowModal] = useState(false);

  const openUpdateIngredientModal = () => {
    setShowModal(true);
  };

  const closeUpdateIngredientModal = () => {
    setShowModal(false);
  };

  const { id, ingredientBase, ingredientConstraints, tags, title } = ingredient;

  const initialValues = {
    id,
    title,
    parentId: ingredientBase.id,
    parentTitle: ingredientBase.title,
    ingredient_constraints: ingredientConstraints,
    tags,
  };

  return (
    <>
      {showModal && (
        <UpdateIngredientModal initialValues={initialValues} closeUpdateIngredientModal={closeUpdateIngredientModal} />
      )}
      <IngredientListItemWrapper>
        <IngredientListItemColumn>
          <p key={parentId}>{ingredient.title}</p>
        </IngredientListItemColumn>
        <IngredientListItemColumn>
          <IngredientListButton onClick={() => openUpdateIngredientModal()}>Edit</IngredientListButton>
        </IngredientListItemColumn>
      </IngredientListItemWrapper>
    </>
  );
};

export default IngredientListItem;
