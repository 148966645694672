import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IngredientModal from '../components/IngredientModal';
import { getIngredients, getParentIngredients, clearIngredients } from '../actions/ingredients';
import { getConstraints } from '../actions';
import { Ingredient, RecipeIngredient, ReduxAction, ConstraintList } from '../types';

import IngredientsPageParentListItem from '../components/IngredientsPageParentListItem';

const StyledButton = styled.button`
  background-color: whitesmoke;
  width: 180px;
  border-radius: 50px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

interface Props {
  getIngredientsAction: (search?: string) => ReduxAction<Ingredient[]>;
  getParentIngredientsAction: (search?: string) => ReduxAction<Ingredient[]>;
  getConstraintsAction: () => ReduxAction<ConstraintList>;
  clearIngredientsAction: () => ReduxAction<void>;
  ingredient: RecipeIngredient;
  ingredients: Ingredient[];
  parentIngredients: Ingredient[];
  index: number;
}
const mapStateToProps = ({ ingredients, parentIngredients, constraints }) => ({
  ingredients,
  parentIngredients,
  constraints,
});

const mapDispatchToProps = (dispatch) => ({
  getIngredientsAction: (search?: string) => dispatch(getIngredients(search)),
  getParentIngredientsAction: (search?: string) => dispatch(getParentIngredients()),
  getConstraintsAction: () => dispatch(getConstraints()),
  clearIngredientsAction: () => dispatch(clearIngredients()),
});

const IngredientsPage: React.FC<Props> = ({
  ingredients,
  parentIngredients,
  getIngredientsAction,
  getParentIngredientsAction,
  getConstraintsAction,
  clearIngredientsAction,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [uniqueParentIngredients, setUniqueParentIngredients] = useState([]);
  useEffect(() => {
    getParentIngredientsAction();
    getConstraintsAction();
  }, []);

  useEffect(() => {
    if (searchValue.length >= 2) {
      getIngredientsAction(searchValue);
    }
    if (!searchValue || searchValue.length < 2) {
      clearIngredientsAction();
    }
  }, [searchValue]);

  useEffect(() => {
    if (ingredients) {
      let currentParentIngredients = ingredients.map((ingredient) => {
        return ingredient.ingredientBase;
      });
      setUniqueParentIngredients([
        ...new Map(currentParentIngredients.map((parent) => [parent['id'], parent])).values(),
      ]);
    }
  }, [ingredients]);

  const [showModal, setShowModal] = useState(false);

  const openUpdateIngredientModal = () => {
    setShowModal(true);
  };

  const closeIngredientModal = () => {
    setShowModal(false);
  };

  const navigateRecipePageHandler = () => {
    navigate('/');
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <ToastContainer />
      <h2>Ingredients</h2>
      <Row>
        <div>
          <input type="text" placeholder="search ingredients" onChange={(e) => handleSearchChange(e)} />
        </div>
        <StyledButton onClick={() => openUpdateIngredientModal()}>New Ingredient</StyledButton>
        <StyledButton className="recipe-link-btn" onClick={navigateRecipePageHandler}>
          View Recipes
        </StyledButton>
      </Row>
      {showModal && <IngredientModal closeIngredientModal={closeIngredientModal} />}

      {parentIngredients &&
        ingredients &&
        uniqueParentIngredients.map((parent, index) => {
          return (
            <IngredientsPageParentListItem
              key={`parent-${parent.id}`}
              ingredients={ingredients}
              parent={parent}
              index={index}
            />
          );
        })}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsPage);
