import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Ingredient } from '../types';
import IngredientListItem from '../components/IngredientListItem';

interface Props {
  ingredients: Ingredient[];
  parent: Ingredient;
  index: number;
}

const IngredientsPageParentListItem: React.FC<Props> = ({ ingredients, parent, index }) => {
  const filteredIngredientsByParent = ingredients.filter((ingredient) => {
    return ingredient.ingredientBase.title === parent.title;
  });

  return (
    <div key={`parent-${parent.id}`}>
      <h1>{parent.title}</h1>
      {filteredIngredientsByParent.map((ingredient, idx) => {
        return <IngredientListItem key={ingredient.id} ingredient={ingredient} parentId={parent.id} />;
      })}
    </div>
  );
};

export default IngredientsPageParentListItem;
